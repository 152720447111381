export const thumbnailParticipantEntry = {
  fields: [
    'title',
    'slug',
    'name',
    'collective_name',
    'sort_key',
    'year_born',
    'year_of_death',
  ],
  populate: {
    place_born: true,
    cover_image: true,
    venue_entries: {
      populate: {
        city_entry: {
          populate: {
            cluster_entry: {
              populate: {
                ...queryFragmentCollectionTypeDefault,
              }
            },
          }
        }
      }
    }
  }
}
